import { useClubStore } from '@/stores/club'
export default {
  computed: {
    clubStore() {
      return useClubStore()
    },
    currentClub() {
      if (this.clubStore.isClub) {
        return this.clubStore
      }
      return null
    },
    clubName() {
      return this.clubStore.name
    },
    clubLogo() {
      return this.clubStore.logo_url
    },
    isClub() {
      return this.clubStore.isClub
    },
    layout() {
      return this.clubStore.layout
    },
    clubEnablePayment() {
      return this.clubStore.clubEnablePayment
    },
    clubEnableDelivery() {
      return this.clubStore.clubEnableDelivery
    },
    clubConfig() {
      return this.clubStore.clubConfig
    },
    clubUid() {
      return this.clubStore.uid
    },
    clubAds() {
      if (this.isClub && this.clubConfig?.product_ads?.length) {
        return this.clubConfig?.product_ads.map(ad => {
          return {
            _uid: ad._uid,
            component: 'GridAd',
            desktop_index: ad.line,
            format: ad.kind === 'LANDSCAPE' ? 'full' : ad.position ? ad.position?.toLowerCase() : null,
            mobile_image: ad.image_url,
            mobile_index: ad.line,
            url: ad.url,
            wide_image: ad.image_url,
          }
        })
      }
    },
    clubBanners() {
      if (this.isClub && this.clubConfig?.banner) {
        return [
          {
            component: 'Slide',
            image_alt: 'club banner',
            mobile_image: this.clubConfig?.banner,
            wide_image: this.clubConfig?.banner,
            testId: 'club-banner',
          },
        ]
      } else if (this.isClub && this.clubConfig?.banners) {
        if (this.clubConfig.banners.some(x => x.desktop_image_url || x.mobile_image_url)) {
          return this.clubConfig.banners.map(banner => {
            return {
              component: 'Slide',
              image_alt: banner.alt,
              mobile_image: banner.mobile_image_url,
              target_value: banner.url,
              wide_image: banner.desktop_image_url,
              testId: 'club-banner',
            }
          })
        }
      }
      return null
    },
  },
}
